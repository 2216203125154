.exp-card {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  background-position: center center;
  background-size: cover;
  transition: all 333ms;
}

.exp-card.active {
  flex-grow: 4;
}

.exp-card > .exp-card-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: background 500ms;
}
.exp-card > .exp-card-content {
  display: flex;
  transition: all 5s;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
