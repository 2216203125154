div.dark-theme > ul.sidenav,
div.dark-theme > nav {
  background-color: transparent;
  box-shadow: none;
}
div.dark-theme > ul.sidenav > li > a {
  color: white;
}
div.dark-theme > nav a:hover, 
div.dark-theme > ul.sidenav a:hover {
  background: rgba(0, 0, 0, 0.4);
}
div.navbar-fixed {
  position: absolute;
}
ul.sidenav > li { width: 100%; }
nav > .nav-wrapper > .brand-logo, 
nav > .nav-wrapper > .sidenav-trigger {
  cursor: pointer;
}
.sidenav-overlay {
  background-color: transparent;
  box-shadow: none;
  z-index: 0;
}

.slope-right { /* slopes down right -> \ */
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 6vw)
  );
          clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 6vw)
  );
}

.slope-left { /* slopes down left -> / */
  -webkit-clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - 6vw), 
    0% 100%
  );
          clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - 6vw), 
    0% 100%
  );
}

.dark-theme.section {
  background: rgb(0, 0, 0);
  color: white;
}
.section > .container {
  width: 90%;
}
.cursor {
  position: absolute;
  background: white;
  width: 13.5px;
  min-height: 1em;
}
@-webkit-keyframes blink {
  0% {
    -webkit-animation-timing-function: steps(1, end);
            animation-timing-function: steps(1, end);
    opacity: 1;
  }
  50% {
    -webkit-animation-timing-function: steps(1, end);
            animation-timing-function: steps(1, end);
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    -webkit-animation-timing-function: steps(1, end);
            animation-timing-function: steps(1, end);
    opacity: 1;
  }
  50% {
    -webkit-animation-timing-function: steps(1, end);
            animation-timing-function: steps(1, end);
    opacity: 0;
  }
}

@-webkit-keyframes type {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes type {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.swiper-slide.about-slide > * {
  padding-left: 6vw;
  padding-right: 6vw;
}
.swiper-container > .btn-floating {
  background: rgb(77, 56, 89);
  position: absolute;
  cursor: pointer;
  top: 35%;
  z-index: 10;
}
.swiper-container > .btn-floating.disabled {
  background: rgb(178, 154, 192);
}
.swiper-container > .btn-floating.chevron_left {
  left: 2.5%;
  right: auto;
}
.swiper-container > .btn-floating.chevron_right {
  left: auto;
  right: 2.5%;
}
.scrollbar::-webkit-scrollbar { width: 5px; }
.scrollbar::-webkit-scrollbar-thumb
{
  background: rgb(255, 255, 255, 0.5);
  border-radius: 7.5px;
}

.exp-card {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  background-position: center center;
  background-size: cover;
  transition: all 333ms;
}

.exp-card.active {
  flex-grow: 4;
}

.exp-card > .exp-card-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: background 500ms;
}
.exp-card > .exp-card-content {
  display: flex;
  transition: all 5s;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.skill-tab-content {
  visibility: hidden;
  opacity: 0;
  transition: all 1s;
}

.skill-tab-content.active {
  visibility: visible;
  opacity: 1;
}

html, body {
  position: relative;
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
}

