.skill-tab-content {
  visibility: hidden;
  opacity: 0;
  transition: all 1s;
}

.skill-tab-content.active {
  visibility: visible;
  opacity: 1;
}
