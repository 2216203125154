div.dark-theme > ul.sidenav,
div.dark-theme > nav {
  background-color: transparent;
  box-shadow: none;
}
div.dark-theme > ul.sidenav > li > a {
  color: white;
}
div.dark-theme > nav a:hover, 
div.dark-theme > ul.sidenav a:hover {
  background: rgba(0, 0, 0, 0.4);
}
div.navbar-fixed {
  position: absolute;
}
ul.sidenav > li { width: 100%; }
nav > .nav-wrapper > .brand-logo, 
nav > .nav-wrapper > .sidenav-trigger {
  cursor: pointer;
}
.sidenav-overlay {
  background-color: transparent;
  box-shadow: none;
  z-index: 0;
}
