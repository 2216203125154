.swiper-slide.about-slide > * {
  padding-left: 6vw;
  padding-right: 6vw;
}
.swiper-container > .btn-floating {
  background: rgb(77, 56, 89);
  position: absolute;
  cursor: pointer;
  top: 35%;
  z-index: 10;
}
.swiper-container > .btn-floating.disabled {
  background: rgb(178, 154, 192);
}
.swiper-container > .btn-floating.chevron_left {
  left: 2.5%;
  right: auto;
}
.swiper-container > .btn-floating.chevron_right {
  left: auto;
  right: 2.5%;
}
.scrollbar::-webkit-scrollbar { width: 5px; }
.scrollbar::-webkit-scrollbar-thumb
{
  background: rgb(255, 255, 255, 0.5);
  border-radius: 7.5px;
}
