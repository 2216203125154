.slope-right { /* slopes down right -> \ */
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 6vw)
  );
}

.slope-left { /* slopes down left -> / */
  clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - 6vw), 
    0% 100%
  );
}
